import React from 'react'
import { FaEdit } from "react-icons/fa";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Services } from "Services";
import { SuccessSwal, warningSwal } from "Util/Toast";
import SkeletonLoad from "Components/TableLoad/load";
import CommonClasses from "Styles/Common.module.css";
import { MdDelete } from "react-icons/md";
import parse from 'html-react-parser';

function States() {
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  const getAllCategories = () => {
    Services.getStates("GET", null, token)
      .then((response) => {
        console.log(response);
        setTimeout(() => {
          setLoading(false);
        }, 200);
        if (response.Status === 1) {
          setData(response.States);
        } else if (response.Status === 0) {
          warningSwal("Warning", response.Message);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert("something went wrong please try again");
        console.log(err);
      });
  };
  useEffect(() => {
    getAllCategories(1);
  }, []);

  const editHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let id = e.currentTarget.getAttribute("id");
    let state = data.find(s => s.StateID == id);
    console.log(state);
    Navigate(`/state/update?id=${id}`, { state: state });
  };


  const deleteHandler = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    let id = e.currentTarget.getAttribute("id");
    try {
      const res = await Services.DeleteState("PUT", null, token, id)
      if (res.Status === 1) {
        SuccessSwal("State Deleted", res.Message);
        getAllCategories(1);
      } else if (res.Status === 0) {
        warningSwal("Warning", res.Message);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };


  return (
    <>
      {loading ? (
        <SkeletonLoad
          TableHead={["StateID", "State", "Priority", "Status", "Actions"]}
        />
      ) : data.length > 0 ? (
        <div className={CommonClasses["Table"]}>
          <table>
            <thead>
              <tr>
                <th>State ID</th>
                <th>State</th>
                <th>Priority</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr
                  id={item.StateID}>
                  <td>{item.StateID}</td>
                  <td>{item.State}</td>
                  <td>{item.Priority}</td>
                  <td>
                    {item.Status === 1 ? (
                      <div
                        className={
                          CommonClasses[
                          "Status-active"
                          ]
                        }>
                        <span>Active</span>
                      </div>
                    ) : (
                      <div
                        className={
                          CommonClasses[
                          "Status-inactive"
                          ]
                        }>
                        <span>InActive</span>
                      </div>

                    )}
                  </td>
                  <td>
                    <div
                      className={
                        CommonClasses["Action-btns"]
                      }>
                      <button
                        id={item.StateID}
                        onClick={editHandler}>
                        <FaEdit />
                      </button>
                      <button
                        id={item.StateID}
                        onClick={deleteHandler}>
                        <MdDelete />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div>
          {" "}
          <p>No States Found</p>
        </div>
      )}
    </>
  );
}

export default States;
